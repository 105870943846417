<template>
  <v-layout column style="min-width: 600px; width: 800px; height:300px; overflow:hidden;">
    <div class="stream-graph" ref="chartdiv">
    </div>
  </v-layout>
</template>

<script>
import * as am4core from '@amcharts/amcharts4/core';
am4core.addLicense('CH351911686');
import * as am4charts from '@amcharts/amcharts4/charts';
import am4themes_animated from '@amcharts/amcharts4/themes/animated';
import moment from 'moment';

am4core.useTheme(am4themes_animated);

export default {
  name: 'Graph',
  props: ['streamHistory', 'liters'],
   methods: {
    formatDate(date) {
      if (!date) return null;

      const [year, month, day] = date.split('-');
      return `${month}/${day}/${year}`;
    },
    parseDate(date) {
      if (!date) return null;

      const [month, day, year] = date.split('/');
      return `${year}-${month.padStart(2, '0')}-${day.padStart(2, '0')}`;
    },
    getTime(time) {
        if (time) {
        return  moment(time).format('L') + ' ' + moment(time).format('hh:mm');
        } else {
        return '';
        }
    },
    updateData(sourceData){
      if (this.chart){
          const data = [];
          if (sourceData.length === 0){
              data.push({ date: new Date(), name: this.getTime(new Date()), value: 0 });
          }else{
            sourceData.forEach((val) => {
              data.push({ date: new Date(val[0]), name: this.getTime(val[0]), value: val[1] });
            });
          }
          this.chart.data = data;
          if (this.liters) {
            this.chart.yAxes.values[0].title.text = this.liters;
          }
      }
    }
   },
  mounted() {
    const chart = am4core.create(this.$refs.chartdiv, am4charts.XYChart);
    chart.rtl = localStorage.getItem('lang') === 'he';
    chart.paddingRight = 20;
    chart.height = 300;
    const data = [];
    const visits = 10;
    if (this.streamHistory && Array.isArray(this.streamHistory)){
      this.streamHistory.forEach((val) => {
        data.push({ date: new Date(val[0]), name: this.getTime(val[0]), value: val[1] });
      });
    }
    chart.data = data;
    const dateAxis = chart.xAxes.push(new am4charts.DateAxis());
    dateAxis.renderer.grid.template.location = 0;

    const valueAxis = chart.yAxes.push(new am4charts.ValueAxis());
    valueAxis.min = 0;
    valueAxis.tooltip.disabled = true;
    valueAxis.renderer.minWidth = 35;
    if (this.liters) {
      valueAxis.title.text = this.liters;
    }
    // switch StepLineSeries to LineSeries to smooth lines between points
    const series = chart.series.push(new am4charts.StepLineSeries());
    series.dataFields.dateX = 'date';
    series.dataFields.valueY = 'value';

    series.tooltipText = '{valueY.value}';
    chart.cursor = new am4charts.XYCursor();

    const scrollbarX = new am4charts.XYChartScrollbar();
    scrollbarX.series.push(series);
    chart.scrollbarX = scrollbarX;

    this.chart = chart;
  },
  beforeDestroy() {
    if (this.chart) {
      this.chart.dispose();
    }
  }
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
.stream-graph {
  height: 320px;
}
</style>